import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CountDisplay from './components/CountDisplay';
import fetch from '../../config/service';
import apiCalls from '../../config/apiCalls';
import Loader from '../App/Loader';
import Piechart from '../graphs/Piecharts';
import DataTable from './components/DataTable';
import io from 'socket.io-client';
import config from '../../config/config';
import { InventoryStatusTableFields } from './components/DashboardData';

const Dashboard = () => {
  const { t } = useTranslation('common');

  const [dashboardData, setDashboardData] = useState({});
  const[ inventoryStatusData, setInventoryStatusData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData] = useState([
    { label: 'Facilities', color: '#d81b60', value: 1200 },
    { label: 'Inventories', color: '#39cccc', value: 300 },
    { label: 'Facility Staff', color: '#605ca8', value: 600 },
    { label: 'Products', color: '#ff851b', value: 700 }
  ]);
  const [facilityData] = useState([
    { label: 'Cabinents', color: '#d81b60', value: 100 },
    { label: 'Unfilled Racks', color: '#39cccc', value: 700 },
    { label: 'Filled Racks', color: '#605ca8', value: 600 },
    { label: 'Damaged Racks', color: '#ff851b', value: 200 }
  ]);
  useEffect(() => {
    const socket = io(config.socketUrl);
    socket.on("connect", () => console.log("SOCKET CONNECTED"))
    socket.emit("sendDashboardData", {});
    socket.on("recieveDashboardData", recieveDashboardData => {
      setDashboardData(recieveDashboardData?.counts);
      setInventoryStatusData(recieveDashboardData?.dashboardData)
    })
    return () => {
      socket.disconnect();
    }
  },[])

  const getMostSoldItems = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('GET', apiCalls.dashboard);
      if (response && response.details) {
        setDashboardData(response.details);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container className="dashboard" style={{ width: '98%', marginLeft: '20px' }}>
      <Loader loader={isLoading} />

      <Row>
        <Col md={12}>
          <h3 className="page-title" style={{ marginBottom: '5px', fontWeight: 600 }}>{t('DashBoard')}</h3>
        </Col>
      </Row>

      {/* Counts */}
      <CountDisplay dashboardData={dashboardData} />

      <div style={{ height: "calc(100vh - 44vh)", overflowY: "scroll", overflowX: "hidden" }}>
      { inventoryStatusData?.length > 0 && 
      <Row>
      <Col lg={12} xl={12}>
        <Card className="dashBoardCardWithScroll">
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{t('INVENTORY STATUS')}</h5>
            </div>
            <DataTable tableFields={InventoryStatusTableFields} results={inventoryStatusData} />
          </CardBody>
        </Card>
      </Col>
    </Row>
      }
        <Row>
          <Col lg={8} xl={8}>
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{t('Most Sold Product')}</h5>
                </div>
                <DataTable />
              </CardBody>
            </Card>
          </Col>

          <Col lg={4} xl={4}>
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{t('Track')}</h5>
                </div>
                <div style={{ width: '80%', margin: 'auto' }}>
                  <Piechart chartOptions={chartData} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={4} xl={4}>
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{t('Facility Status')}</h5>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                  <div style={{ width: '80%', margin: 'auto' }}>
                    <Piechart chartOptions={facilityData} />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={8} xl={8}>
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{t('INVENTORY STATUS')}</h5>
                </div>
                <DataTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Dashboard;
